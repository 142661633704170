.hero
{
  background-color: #333;
  background-image:url('/images/home-hero-small.jpg');
  background-size:cover;
  color:#fff;
  overflow: hidden;
}

.hero .overlay
{
  position: absolute;
  top:0;
  left: 0;
  width:100%;
  height:100%;
  z-index: 1;
  opacity: 0.7;
  background: #000;
}

.hero .wrap
{
  min-height:100vh;
  position: relative;
  z-index: 2;
}

.hero .section-rule
{
  height:20px;
  background-color:#679c42;
}


.hero .section-heading
{
  font-size:40px;
  line-height: 1;
  font-weight: 600;
  margin: 0;
}

.hero .section-subheading
{
  font-size:16px;
  margin: 10px 0 0 0;
}

.video-bg
{
  display:none;
}



@include breakpoint($medium) { 

  /*
  .hero
  {
    background-image:url('/images/home-hero-mock2.jpg');
  }
  */

  .hero .wrap
  {
    min-height:80vh;
  }

  .hero .section-heading
  {
    font-size:80px;
    font-weight: 600;
    margin: 0;
  }

  .hero .section-subheading
  {
    font-size:24px;
     margin: 0;
  }

  .hero .overlay
  {
    background: linear-gradient(135deg, #333 45%, #000 55%);
  }

  .video-bg
  {
    display:inline;
    position:absolute;
    top:0;
    width:100%;
    height:100%;
    z-index: 0;
  }

  .video-bg video
  {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:cover;
  }

}
