@import 'flexblocks';

.justify-between
{
  justify-content:space-between;
}
.justify-center
{
  justify-content:center;
}

.align-items-top
{
  align-items: flex-start !important;
}

.grid-2col {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
}

.grid-3col {
  display: grid;
  grid-template-columns: 33% 33%  33%;
  grid-gap: 10px;
}

.grid-4col {
  display: grid;
  grid-template-columns: 25% 25%  25% 25%;
  grid-gap: 10px;
}

.flex-columns > div
{
  margin-bottom: $space-3;
}

.flex-columns h3
{
  font-weight: 600;
  line-height: 1.25;
  font-size: 22px;
  margin: 0;
}

@include breakpoint($medium) { 

  .flex
  {
    display:flex;
  }

  .flex-columns
  {
    display:flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
  }

  .flex-panels
  {
    display:flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-panels > div,
  .flex-columns > div
  {
    padding:30px;
  }

  $flex-column: 100%/12;

  .flex-panel-1 { width: $flex-column * 1; }
  .flex-panel-2 { width: $flex-column * 2; }
  .flex-panel-3 { width: $flex-column * 3; }
  .flex-panel-4 { width: $flex-column * 4; }
  .flex-panel-5 { width: $flex-column * 5; }
  .flex-panel-6 { width: $flex-column * 6; }
  .flex-panel-7 { width: $flex-column * 7; }
  .flex-panel-8 { width: $flex-column * 8; }
  .flex-panel-9 { width: $flex-column * 9; }
  .flex-panel-10 { width: $flex-column * 10; }
  .flex-panel-11 { width: $flex-column * 11; }
  .flex-panel-12 { width: $flex-column * 12; }

  .flex-split
  {
    align-items:flex-start;  
  }

  .flex-split > div
  {
    width:50%;
  }


}
