@include breakpoint($medium) 
{ 
    
  .services-web
  {
    background-image: url('/images/web.jpg');
  }

  .services-marketing
  {
    background-image: url('/images/marketing.jpg');
  }

  .services-creative
  {
    background-image: url('/images/creative.jpg');
  }

  .section-growth-playbook
  {
    background-image: url('/images/growth-playbook.jpg');
    
  }
}
