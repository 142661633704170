footer section {
  display: flex;
  justify-content: center;
  background-color: #333;
  padding:20px 0;
}

.footer {
  color:#999;
}

.footer li {
  padding:5px 0;
}

footer ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-size:14px;
}

footer a
{
  color:#999;
}

footer a:hover
{
  color:$green;
}

footer .wordmark
{
  margin-bottom: 10px;
  line-height: 1;
  color:#fff;
}

.wordmark span:first-child
{
  color:$green;
}

 .copyright {
  color:#999;
  font-size:12px;
}

.certifications { 
  background:lighten(#999,20%);
}

.certifications > div
{
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-flow:wrap;
}

.certifications > div > div
{
  width:45%;
}

.certifications img {
  width: 100%;
  height: auto;
}

@include breakpoint($small) { 
  
  .certifications > div > div
  {
    width:20%;
  }

}

@include breakpoint($medium) { 

  .footer {
    display: flex;
    justify-content:space-between;
  }
  
  .copyright {
    display: flex;
    justify-content:flex-end;
  }

  .copyright li {
    padding:0 10px;
  }

  .certifications > div > div { 
    height:70px;
  }

  .certifications img {
    height: 100%;
    width:auto;
  }

}
