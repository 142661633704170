.hero-stark
{
  //background:#1b3e66;
  background:#333;
  color:#fff;
}

.hero-stark .wrap
{
  min-height:100vh;
  position: relative;
  z-index: 1;
  text-align:center;
}

.hero-stark .section-topic
{
  margin: 3.5em auto;
  font-weight: 600;
  letter-spacing: .25em;
  font-size: 18px;
  color:$green;
}

.hero-stark .section-topic:after
{
  content: "";
  display: block;
  width: 100%;
  max-width: 100px;
  height: 2px;
  background: darken(#fff,40%);
  position: relative;
  bottom: -2em;
  margin: auto;
}

@include breakpoint($medium) 
{ 

  .hero-stark .wrap
  {
    min-height:60vh;
  }

}

