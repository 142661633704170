
.mobile-nav 
{
  position: absolute;
  top: 0px;
  padding: 0px;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  transition: left 500ms ease 0s;
}

.mobile-nav .toggle
{
  cursor: pointer;
  background-color: none;
  width: 10%;
  height: 100%;
}

.mobile-nav .nav
{
  width: 90%;
  height: 100%;
  background-color: white;
  padding:20px;
  overflow:auto;
  display:flex;
  flex-direction: column;
}

.mobile-nav .nav img
{
  height:100%;
  width:auto;
}

.mobile-nav .nav a
{
  display:block;
  text-align:center;
  border-bottom:1px solid #efefef;
  padding:10px 0;
}

.mobile-nav .nav a:first-child
{
  height: 70px;
  margin-bottom:20px;
  border:none;
}

body.show-mobile-nav
{
  position: fixed;
}

body.show-mobile-nav .mobile-nav
{
  left: 0;
}

body.show-mobile-nav .mobile-nav .toggle
{
  background-color: rgba(0, 0, 0, 0.2);
}
