$size-default: 18px;

$sm-size-6: 50%;
$sm-size-5: 75%;
$sm-size-4: 100%;
$sm-size-3: 120%;
$sm-size-2: 135%;
$sm-size-1: 175%;

$size-6: 50%;
$size-5: 75%;
$size-4: 100%;
$size-3: 150%;
$size-2: 200%;
$size-1: 300%;

/*
$size-4: $size-default;
$size-3: $size-default * 2 - 8px;
$size-2: $size-default * 3 - 8px;
$size-1: $size-default * 4 - 8px;
*/
