.container,
.section-container
{
  width: 90%;
  max-width:1200px;
  margin-left: auto;
  margin-right: auto;
}


.right-image img
{
  position: absolute;
  bottom: -65px;
  right: -175px;
  width: 60%;
}
.left-image img
{
  position: absolute;
  bottom: -65px;
  left: -175px;
  width: 60%;
}

