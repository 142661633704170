header
{
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 100;
  //opacity:.8;
  background-color: $soft-white;
  top: 0px;
  //border-bottom: 1px solid #ccc;
}

header .container
{
  display:flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

@include breakpoint($medium) { 
  
  header
  {
    height: 120px;
  }

  header .container
  {
    height: 90px;
  }

}

@import 'header/toggle';
@import 'header/logo';
@import 'header/nav';
@import 'header/subnav';
@import 'header/contact';
@import 'header/mobilenav';


