.list-none
{
  list-style: none;
  margin:0;
  padding:0;
}

.list-heading
{
  color:#000;
  font-weight:bold;
  margin-bottom:10px;
}
