.story-content .statistics ul
{
  padding:0;
  margin:0;
  list-style:none;
}

.story-content .statistics li span
{
  text-transform: uppercase;
  font-weight:bold;
}

.story-content .statistics ul li
{
  margin-bottom:25px;
  white-space: nowrap;
}

.story-content .statistics ul li li
{
  margin-bottom:0;
}

.story-chroma .story-content .quote,
.story-chroma .story-content .statistics span
{
  color:red;
}

.story-sfymca .story-content .quote,
.story-sfymca .story-content .statistics span
{
  color:#ff6600;
}

@include breakpoint($medium) { 

  .story-content
  {
    padding:20px;
    background:rgba(255,255,255,1);
    display:flex;
    align-items: flex-start;
    border-top:10px solid #999;
  }

  .story-content > div
  {
    padding:20px;
  }

  .story-content .statistics ul
  {
    font-size:70%;
    font-weight:bold;
  }

  .story-content .quote
  {
    margin: 0;
    line-height:1.75;
  }

  .story-content .author
  {
    color:#000;
    margin: 10px 0 0 20px;
    line-height:1.75;
  }

  .story-content .topic
  {
    text-transform: uppercase;
    font-weight: bold;
    color:#999;
  }

  .story-content .heading
  {
    font-size:30px;
    font-weight:300;
  }

  .story-content .statistics ul ul
  {
    font-size:130%;
    font-weight:300;
    text-transform: none;
    color:#666;
  }

  .story-stacked .story-content
  {
    flex-direction: column-reverse;
  }

  .story-stacked .story-content > div
  {
    width:100%;
  }

  .story-stacked-left .wrap > div,
  .story-stacked-right .wrap > div
  {
    width:60%;
  }
  .story-stacked .statistics > ul
  {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .story-stacked .story-content
  {
    border-top:none;
    background:none;
  }

  .story-chroma .story-content
  {
    border-top:10px solid red;
  }

  .story-sfymca .story-content
  {
    border-top:10px solid #ff6600;
  }
    
  .home-stories .section-summary
  {
    display: flex;
    justify-content: center;
    > div { width:50%; }
  }

  .story-chroma
  {
    background-image: url('/images/chroma.jpg');
  }

  .story-sfymca
  {
    background-image: url('/images/souixfallsymca.jpg');
  }

  .story-care {
    background-image: url('/images/care.jpg');
  }

  .story-sfymca .wrap
  {
    min-height: 50vw;
  }
 
}

