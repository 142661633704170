.home-intro .section-subheading
{
  font-size: 14px;
  display:flex;
  justify-content:flex-start;
  color:$green;
  list-style:none;
  margin:0;
  padding:0;
  margin-bottom:20px;

  li {
    text-transform:uppercase;
    margin:0;
  }
  li:after{
    content:"•";
    padding:0 10px;
  }

  li:last-child:after{
    content:"";
    padding:0;
  }
  
}

@include breakpoint($medium) { 

  .home-intro .section-subheading
  {
    font-size: 16px;
    justify-content:center;
  }

}
