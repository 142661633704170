.section-quote
{
  display:flex;
  text-align: left;
}

.section-quote > div
{
  width:60%;
}

.section-quote .quote p
{
  margin:0;
}

.section-quote .quote
{

  display:flex;
  align-items: center;
  width:40%;
  color:$green;
  font-size:175%;
  font-weight:300;
  padding-right:40px;
  text-align: right;
}

.section-quote .quote .source
{
  font-size:60%;
  color:#ccc;
  display:inline;
  margin-left:10px;
}


.section-quote-background > div
{
  padding-left:60px;
}

.section-quote-background .quote
{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding:0;
}

.section-quote-background .quote > div
{
  background-color: rgba(255,255,255,0.85);
  line-height: 1.25;
  padding:20px 0;
}

