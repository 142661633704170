nav{
  display:none;
  height:100%;
}

nav ul
{
  display:flex;
  margin:0;
  height:100%;
  padding:0;
  list-style:none;
}

nav li
{
  display: flex;
  align-items: center;
}

nav a
{
  display: flex;
  align-items:center;
  margin: 0;
  padding: 20px;
  text-transform:uppercase;
  color:#000;
  font-size:16px;
  font-weight: bold;
  line-height: 1;
  height: 100%;
}

nav a.active
{
  color:$green;
}

nav a svg
{
  height:auto;
  width:10px;
  margin-left:5px;
  fill:green;
}

/*
nav a:hover,
nav a.active
{
  color:$green;
}
*/

@include breakpoint($medium) { 
  

  nav{
    display:block;
  }

}
