.service-plugs h2
{
  margin-bottom: 40px;
}

.service-plug
{
  margin-bottom:30px;
}
/*
.service-plug:after
{
  content: "";
  display: block;
  margin-top:20px;
  width:20%;
  border-bottom:solid 1px #ccc;
}
*/
.service-plug h3
{
  margin:0;
}

.service-plug p
{
  margin:0 0 10px 0;
}

.service-plug h3
{
  color:#fff;
}
