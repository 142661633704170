 .integrated
{
  background:#333;
  color:lighten(#333,40%);
}

.integrated li
{ 
  background:darken(#333,6%);
  color:lighten(#333,35%);
  text-align:left;
}

.integrated h3
{ 
  line-height:1.25;
  color:#fff;
}

.integrated li
{

  position: relative;
  z-index: 0;
}

.integrated li > div
{
  margin-top:100px;
}

.integrated .number
{
  color:#999;
  border:5px solid #999;
  border-radius:75px;
  width:150px;
  height:150px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:150px;
  font-weight: 300;
  overflow: hidden;
  position:absolute;
  opacity:0.1;
  top:0;
  left:0;
  z-index: -1;
}

@include breakpoint($medium) 
{ 

  .integrated p
  {
    line-height:1.75;
    font-size:15px;
  }
  
}
