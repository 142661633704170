@charset "UTF-8";
/*
$size-4: $size-default;
$size-3: $size-default * 2 - 8px;
$size-2: $size-default * 3 - 8px;
$size-1: $size-default * 4 - 8px;
*/
.white {
  color: #fff;
}

.green {
  color: #3fa208;
}

.black {
  color: #000;
}

.gray, .grey {
  color: #9d9d9d;
}

.bg-light-gray, .section-background {
  background-color: #f6f6f6;
}

.bg-green, .section-background-colored {
  background-color: #669933;
}

.p0 {
  padding: 0px;
}

.p1 {
  padding: 10px;
}

.p2 {
  padding: 20px;
}

.p3 {
  padding: 40px;
}

.px0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px1 {
  padding-left: 10px;
  padding-right: 10px;
}

.px2 {
  padding-left: 20px;
  padding-right: 20px;
}

.px3 {
  padding-left: 40px;
  padding-right: 40px;
}

.py0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py3 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pl0 {
  padding-left: 0px;
}

.pl1 {
  padding-left: 10px;
}

.pl2 {
  padding-left: 20px;
}

.pl3 {
  padding-left: 40px;
}

.pr0 {
  padding-right: 0px;
}

.pr1 {
  padding-right: 10px;
}

.pr2 {
  padding-right: 20px;
}

.pr3 {
  padding-right: 40px;
}

.pt0 {
  padding-top: 0px;
}

.pt1 {
  padding-top: 10px;
}

.pt2 {
  padding-top: 20px;
}

.pt3 {
  padding-top: 40px;
}

.pb0 {
  padding-bottom: 0px;
}

.pb1 {
  padding-bottom: 10px;
}

.pb2 {
  padding-bottom: 20px;
}

.pb3 {
  padding-bottom: 40px;
}

.m0 {
  margin: 0px;
}

.m1 {
  margin: 10px;
}

.m2 {
  margin: 20px;
}

.m3 {
  margin: 40px;
}

.mx0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx1 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx2 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx3 {
  margin-left: 40px;
  margin-right: 40px;
}

.my0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my3 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.ml0 {
  margin-left: 0px;
}

.ml1 {
  margin-left: 10px;
}

.ml2 {
  margin-left: 20px;
}

.ml3 {
  margin-left: 40px;
}

.mr0 {
  margin-right: 0px;
}

.mr1 {
  margin-right: 10px;
}

.mr2 {
  margin-right: 20px;
}

.mr3 {
  margin-right: 40px;
}

.mt0 {
  margin-top: 0px;
}

.mt1 {
  margin-top: 10px;
}

.mt2 {
  margin-top: 20px;
}

.mt3 {
  margin-top: 40px;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb1 {
  margin-bottom: 10px !important;
}

.mb2 {
  margin-bottom: 20px !important;
}

.mb3 {
  margin-bottom: 40px !important;
}

@media (min-width: 980px) {
  .offset0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .offset1 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .offset2 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .offset3 {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.flex-blocks {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-blocks > li {
  background-color: #fff;
  border: solid 1px #ccc;
  border-radius: 10px;
  min-height: 200px;
  display: block;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.flex-blocks ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.flex-blocks > li {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-blocks-noborder > li {
  border: none;
  box-shadow: none;
}

.flex-blocks-center > li {
  align-items: center;
  text-align: center;
}

@media (min-width: 980px) {
  .flex-blocks {
    display: flex;
    flex-wrap: wrap;
  }
  .flex-blocks > li {
    flex: 1 1 20%;
    margin: 5px;
  }
  .flex-blocks-nowrap {
    flex-wrap: nowrap;
  }
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-items-top {
  align-items: flex-start !important;
}

.grid-2col {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
}

.grid-3col {
  display: grid;
  grid-template-columns: 33% 33%  33%;
  grid-gap: 10px;
}

.grid-4col {
  display: grid;
  grid-template-columns: 25% 25%  25% 25%;
  grid-gap: 10px;
}

.flex-columns > div {
  margin-bottom: 40px;
}

.flex-columns h3, .flex-columns .h3 {
  font-weight: 600;
  line-height: 1.25;
  font-size: 22px;
  margin: 0;
}

@media (min-width: 980px) {
  .flex {
    display: flex;
  }
  .flex-columns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
  }
  .flex-panels {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-panels > div,
  .flex-columns > div {
    padding: 30px;
  }
  .flex-panel-1 {
    width: 8.33333%;
  }
  .flex-panel-2 {
    width: 16.66667%;
  }
  .flex-panel-3 {
    width: 25%;
  }
  .flex-panel-4 {
    width: 33.33333%;
  }
  .flex-panel-5 {
    width: 41.66667%;
  }
  .flex-panel-6 {
    width: 50%;
  }
  .flex-panel-7 {
    width: 58.33333%;
  }
  .flex-panel-8 {
    width: 66.66667%;
  }
  .flex-panel-9 {
    width: 75%;
  }
  .flex-panel-10 {
    width: 83.33333%;
  }
  .flex-panel-11 {
    width: 91.66667%;
  }
  .flex-panel-12 {
    width: 100%;
  }
  .flex-split {
    align-items: flex-start;
  }
  .flex-split > div {
    width: 50%;
  }
}

.list-none {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-heading {
  color: #000;
  font-weight: bold;
  margin-bottom: 10px;
}

.smaller {
  font-size: 85% !important;
}

.tiny {
  font-size: 60% !important;
}

.larger {
  font-size: 150% !important;
}

.thin {
  font-weight: 300 !important;
}

.muted {
  opacity: .65;
}

.bold {
  font-weight: 600 !important;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 1.5;
}

.line-height-3 {
  line-height: 2;
}

.line-height-4 {
  line-height: 4;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.border-bottom {
  border-bottom: solid 1px #000;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans',sans-serif;
  font-size: 18px;
  line-height: 1.75;
  color: #333;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #3fa208;
}

img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
}

h1, .h1 {
  font-size: 175%;
}

h2, .h2 {
  font-size: 135%;
}

h3, .h3 {
  font-size: 120%;
}

h4, .h4 {
  font-size: 100%;
}

h5, .h5 {
  font-size: 75%;
}

h6, .h6 {
  font-size: 50%;
}

@media (min-width: 768px) {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
    font-weight: 300;
  }
  h1, .h1 {
    font-size: 300%;
  }
  h2, .h2 {
    font-size: 200%;
  }
  h3, .h3 {
    font-size: 150%;
  }
  h4, .h4 {
    font-size: 100%;
  }
  h5, .h5 {
    font-size: 75%;
  }
  h6, .h6 {
    font-size: 50%;
  }
}

@media (min-width: 768px) {
  body {
    font-size: 95%;
  }
}

.container,
.section-container {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.right-image img {
  position: absolute;
  bottom: -65px;
  right: -175px;
  width: 60%;
}

.left-image img {
  position: absolute;
  bottom: -65px;
  left: -175px;
  width: 60%;
}

.section-heading {
  font-size: 200%;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.25;
}

.section-lead {
  margin: 0;
}

.section-narrow {
  display: flex;
  justify-content: center;
}

.section-narrow > div {
  width: 65%;
}

.section-spaced > div {
  margin-bottom: 20px;
}

.section-image {
  margin-bottom: 20px;
}

.section-topic {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 90%;
  color: #999;
}

.section-title {
  text-transform: uppercase;
  color: #666;
  font-size: 14px;
  font-weight: bold;
}

main section .wrap {
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
  padding: 50px 0;
  display: flex;
  align-items: center;
}

main section .wrap-center {
  justify-content: center;
}

main section .wrap-center > div {
  width: 100%;
}

.section-divider {
  display: flex;
  justify-content: center;
}

.section-divider > div {
  background-color: #3fa208;
  height: 5px;
  width: 20%;
  margin: 40px 0;
}

main section {
  border-bottom: 1px solid #c3c3c3;
  position: relative;
}

section.no-border {
  border-bottom: none;
}

.section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

@media (min-width: 980px) {
  main section, article {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right center;
  }
  .section-right {
    background-position: left center;
  }
  .section-image {
    display: none;
  }
  main section .wrap {
    min-height: 40vw;
  }
  main section .wrap-short {
    min-height: 20vw;
    padding: 0;
  }
  .section-heading {
    font-size: 250%;
  }
  .section-lead {
    font-size: 115%;
    line-height: 1.75;
  }
  main section .wrap-half > div {
    width: 50%;
  }
  main section .wrap-full {
    max-width: initial;
  }
  .section-right .wrap {
    justify-content: flex-end;
  }
  main section .wrap-center-narrow > div {
    width: 75%;
  }
  .section-right .wrap > div,
  .section-left .wrap > div,
  main section .wrap-right > div,
  main section .wrap-left > div {
    width: 40%;
  }
  main section.wrap-left,
  article.wrap-left {
    background-position: right center;
  }
}

header {
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 100;
  background-color: #fcfbfa;
  top: 0px;
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

@media (min-width: 980px) {
  header {
    height: 120px;
  }
  header .container {
    height: 90px;
  }
}

.menu-toggle {
  padding: 10px;
  cursor: pointer;
}

.menu-toggle > div {
  display: flex;
  height: 19px;
  justify-content: space-between;
  flex-direction: column;
  width: 28px;
}

.menu-toggle span {
  height: 3px;
  width: 28px;
  display: block;
  background: #3fa208;
  border-radius: 6px;
}

@media (min-width: 980px) {
  .menu-toggle {
    display: none;
  }
}

.logo {
  width: auto;
  height: 100%;
  display: flex;
  padding: 15px;
  margin: 0;
  align-items: flex-end;
  text-decoration: none;
}

.logo img {
  width: auto;
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.wordmark {
  font-family: 'Open Sans',arial,sans-serif;
  font-weight: 300;
  font-size: 26px;
  color: #333;
}

header .wordmark {
  display: none;
}

@media (min-width: 768px) {
  header .wordmark {
    display: block;
  }
}

nav {
  display: none;
  height: 100%;
}

nav ul {
  display: flex;
  margin: 0;
  height: 100%;
  padding: 0;
  list-style: none;
}

nav li {
  display: flex;
  align-items: center;
}

nav a {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 20px;
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  height: 100%;
}

nav a.active {
  color: #3fa208;
}

nav a svg {
  height: auto;
  width: 10px;
  margin-left: 5px;
  fill: green;
}

/*
nav a:hover,
nav a.active
{
  color:$green;
}
*/
@media (min-width: 980px) {
  nav {
    display: block;
  }
}

.show-subnav {
  position: relative;
}

.subnav-panel {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #3fa208;
}

.subnav-drop-down-item ul {
  background: #3fa208;
}

.subnav-panel li {
  position: relative;
}

.subnav-panel li ul {
  position: absolute;
  left: 100%;
  top: 0;
}

.show-subnav-active {
  background-color: #3fa208;
  color: #fff !important;
}

nav .show-subnav-active a {
  color: #fff;
}

.show-subnav-active svg {
  fill: #fff;
}

.subnav-panel ul {
  display: block;
  margin: 20px;
  padding: 0;
  list-style: none;
  justify-content: space-around;
  min-width: 230px;
}

.subnav-panel li {
  color: #fff;
  font-size: 14px;
}

.subnav-panel li a,
.subnav-panel li span {
  display: block;
  padding: 10px 20px;
}

.subnav-drop-down-item {
  position: relative;
  width: 100%;
}

.subnav-drop-down-item:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 40%;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #fff;
}

.subnav-drop-down-item:hover,
.subnav-panel a:hover {
  background-color: #368a07;
  cursor: pointer;
}

.subnav-panel ul,
.subnav-panel li {
  margin: 0;
  padding: 0;
}

.subnav-panel ul ul {
  display: none;
  height: initial;
}

/*
.subnav-panel > div
{
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}



.subnav-panel ul ul
{
  display:block;
}
*/
.subnav-panel a {
  display: flex;
  align-items: flex-center;
  margin: 0;
  padding: 5px 0;
  text-transform: none;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  line-height: initial;
  width: 100%;
}

@media (min-width: 980px) {
  .subnav-panel-active {
    opacity: 1;
    visibility: visible;
  }
}

header .contact-info {
  height: 30px;
  font-size: 16px;
  color: #fff;
  background: #3fa208;
}

header .contact-info ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

header .contact-info ul,
header .contact-info li {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

header .contact-info li {
  margin: 0 0 0 10px;
  display: none;
}

header .contact-info li.phone {
  display: flex;
}

header .contact-info a {
  color: #fff;
  display: block;
  height: inherit;
  display: flex;
  align-items: center;
  flex-flow: nowrap;
}

header .contact-info span {
  white-space: nowrap;
}

header .contact-info svg {
  width: auto;
  height: 100%;
  padding: 8px;
  fill: white;
}

@media (min-width: 980px) {
  header .contact-info {
    background: #fff;
    color: #666;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
  }
  header .contact-info .container {
    justify-content: flex-end;
  }
  header .contact-info a {
    color: #999;
  }
  header .contact-info svg {
    fill: #3fa208;
  }
  header .contact-info li {
    display: flex;
  }
}

.mobile-nav {
  position: absolute;
  top: 0px;
  padding: 0px;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  transition: left 500ms ease 0s;
}

.mobile-nav .toggle {
  cursor: pointer;
  background-color: none;
  width: 10%;
  height: 100%;
}

.mobile-nav .nav {
  width: 90%;
  height: 100%;
  background-color: white;
  padding: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.mobile-nav .nav img {
  height: 100%;
  width: auto;
}

.mobile-nav .nav a {
  display: block;
  text-align: center;
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}

.mobile-nav .nav a:first-child {
  height: 70px;
  margin-bottom: 20px;
  border: none;
}

body.show-mobile-nav {
  position: fixed;
}

body.show-mobile-nav .mobile-nav {
  left: 0;
}

body.show-mobile-nav .mobile-nav .toggle {
  background-color: rgba(0, 0, 0, 0.2);
}

footer section {
  display: flex;
  justify-content: center;
  background-color: #333;
  padding: 20px 0;
}

.footer {
  color: #999;
}

.footer li {
  padding: 5px 0;
}

footer ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}

footer a {
  color: #999;
}

footer a:hover {
  color: #3fa208;
}

footer .wordmark {
  margin-bottom: 10px;
  line-height: 1;
  color: #fff;
}

.wordmark span:first-child {
  color: #3fa208;
}

.copyright {
  color: #999;
  font-size: 12px;
}

.certifications {
  background: #cccccc;
}

.certifications > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
}

.certifications > div > div {
  width: 45%;
}

.certifications img {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .certifications > div > div {
    width: 20%;
  }
}

@media (min-width: 980px) {
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .copyright {
    display: flex;
    justify-content: flex-end;
  }
  .copyright li {
    padding: 0 10px;
  }
  .certifications > div > div {
    height: 70px;
  }
  .certifications img {
    height: 100%;
    width: auto;
  }
}

.hero-stark {
  background: #333;
  color: #fff;
}

.hero-stark .wrap {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  text-align: center;
}

.hero-stark .section-topic {
  margin: 3.5em auto;
  font-weight: 600;
  letter-spacing: .25em;
  font-size: 18px;
  color: #3fa208;
}

.hero-stark .section-topic:after {
  content: "";
  display: block;
  width: 100%;
  max-width: 100px;
  height: 2px;
  background: #999999;
  position: relative;
  bottom: -2em;
  margin: auto;
}

@media (min-width: 980px) {
  .hero-stark .wrap {
    min-height: 60vh;
  }
}

.video-thumbnail {
  padding: 20px;
}

.internal-notes {
  position: absolute;
  font-size: 11px;
  color: magenta;
  width: 10%;
  left: 10px;
  top: 10px;
  z-index: 30;
}

.internal-notes .title {
  font-weight: bold;
  text-transform: uppercase;
}

.internal-notes .icon {
  width: 20px;
  height: 20px;
  background: magenta;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.internal-notes .contents {
  position: absolute;
  top: 0;
  left: 25px;
  display: none;
  background: white;
  padding: 10px;
  border: solid 1px #ccc;
}

.internal-notes .contents-active {
  display: block;
}

.internal-notes ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.internal-notes li {
  margin-bottom: 10px;
  padding: 0 20px 0 0;
}

label {
  font-size: 11px;
  text-transform: uppercase;
  color: #333;
}

input, select, textarea {
  appearance: none;
  border: solid 1px #ccc;
  padding: 10px;
  width: 100%;
  font-size: 14px;
}

textarea {
  resize: none;
}

form > div {
  margin-bottom: 10px;
}

button, input {
  overflow: visible;
}

button, input, optgroup, select, textarea {
  font: inherit;
  margin: 0;
}

.fj_contact_info {
  display: none;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
  width: auto;
}

[type="submit"] {
  background: #3fa208;
  border: none;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  font-family: 'Open Sans',arial,sans-serif;
  font-size: 16px;
  height: 48px;
  letter-spacing: .5px;
  line-height: 48px;
  padding: 0 24px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  margin-top: 20px;
}

.button {
  background-color: #3fa208;
  padding: 15px 30px 15px 50px;
  border-radius: 30px;
  color: #fff;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.button > span {
  position: absolute;
  top: 50%;
  left: 25px;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: inherit;
  transform: translate(-50%, -50%);
}

.link {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.link {
  color: #fff;
  background-color: #3fa208;
  padding: 15px 30px 15px 50px;
  border-radius: 30px;
}

.link > span {
  position: absolute;
  top: 50%;
  left: 25px;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: inherit;
  transform: translate(-50%, -50%);
}

@media (min-width: 980px) {
  .link {
    padding: 0px 0px 0px 25px;
    background: none;
    border-radius: 0;
    border: none;
    color: #3fa208;
  }
  .link > span {
    left: 10px;
    width: 30px;
    height: 30px;
  }
  .link > span path {
    fill: #3fa208;
  }
}

.column-list div.icon {
  padding: 5px 15px 0 0;
  fill: #3fa208;
  height: 40px;
}

.column-list div.icon svg {
  height: 100%;
  width: auto;
}

.column-list h3, .column-list .h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.column-list p {
  margin-top: 0;
  font-size: 90%;
}

.column-list .link {
  font-weight: bold;
}

.column-list .link a {
  display: block;
}

.technology-list {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 20px;
  color: #333;
}

.technology-list > ul {
  padding: 20px;
  margin: 0;
  background-color: #efefef;
  color: #666;
  border-radius: 5px;
}

.technology-list ul {
  list-style: none;
  text-align: left;
}

.technology-list ul ul {
  padding: 10px 20px 0 0;
}

.technology-list ul ul ul {
  padding: 0 20px;
}

.technology-list .heading {
  font-weight: bold;
  text-transform: uppercase;
}

.technology-list li {
  margin-bottom: 10px;
  font-size: 95%;
}

.technology-list li li li {
  margin-bottom: 0;
  font-weight: 300;
}

@media (min-width: 980px) {
  .column-list {
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
  }
  .column-list > div {
    flex: 1 1 100%;
  }
  .column-list-3col {
    flex-wrap: wrap;
  }
  .column-list-3col > div {
    width: 30%;
  }
  .column-list > div {
    text-align: left;
    display: flex;
    padding: 0 20px;
    margin-bottom: 60px;
    align-items: flex-start;
  }
  .column-list div div {
    width: 85%;
  }
  .column-list div.icon {
    width: 15%;
  }
}

.section-list {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.section-list li {
  margin-bottom: 30px;
  padding: 10px;
}

.section-list-bullets {
  margin-top: 30px;
}

.section-list-bullets li {
  margin-bottom: 0px;
}

.section-list-columns {
  column-count: 2;
  column-gap: 40px;
  column-rule: 1px solid #3fa208;
}

.section-list-columns li {
  page-break-inside: avoid;
  break-inside: avoid;
}

.section-list-columns-3 {
  column-count: 3;
}

.section-numbered-list,
.section-numbered-list > div > div {
  display: flex;
  text-align: left;
}

.section-numbered-list > div > div {
  padding: 20px;
}

.section-numbered-list h5, .section-numbered-list .h5 {
  font-size: 150%;
  color: #3fa208;
  font-weight: bold;
  padding-right: 10px;
}

.section-numbered-list p {
  margin: 2px 0 0 0;
  font-weight: bold;
}

.section-long-list {
  display: flex;
  text-align: left;
  margin-top: 40px;
}

.section-long-list > div {
  flex: 1 1 100%;
  padding: 0 20px;
  font-size: 90%;
}

.section-long-list > div > div {
  margin-bottom: 20px;
}

.section-long-list h3, .section-long-list .h3,
.section-long-list p {
  margin: 0;
}

.section-long-list h3, .section-long-list .h3 {
  text-transform: uppercase;
  font-size: 110%;
  font-weight: bold;
}

.knockout .section-long-list h3, .knockout .section-long-list .h3 {
  color: #3fa208;
}

.knockout {
  background-color: #333;
  color: #999999;
}

.knockout li {
  background: #242424;
  color: #8c8c8c;
  text-align: left;
}

.knockout .section-topic {
  color: #3fa208;
}

.knockout .section-heading {
  color: #cccccc;
}

.knockout .section-lead {
  font-size: 120%;
}

.knockout label {
  color: #cccccc;
}

.knockout input[type="text"],
.knockout input[type="email"],
.knockout input[type="tel"],
.knockout textarea {
  background: #666;
  border-color: #999;
  color: #999;
}

/*
.knockout h3
{ 
  margin-top:125px;
  line-height:1.25;
  color:#fff;
}

.knockout p
{
  line-height:1.75;
  font-size:15px;
}
*/
.knockout li > div {
  position: relative;
  z-index: 0;
}

.knockout .number {
  color: #999;
  border: 5px solid #999;
  border-radius: 75px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150px;
  font-weight: 300;
  overflow: hidden;
  position: absolute;
  opacity: 0.1;
  top: 0;
  left: 0;
  z-index: -1;
}

.contact-page-form > div {
  display: flex;
}

.contact-page-form > div div {
  padding: 10px;
  text-align: left !important;
}

.contact-page-form span {
  font-size: 12px;
  opacity: 0.4;
  text-transform: uppercase;
}

.contact-hero .contact-info h3, .contact-hero .contact-info .h3 {
  text-transform: uppercase;
  font-size: 90%;
  font-weight: bold;
  color: #666;
  border-bottom: 1px solid #fff;
}

@media (min-width: 980px) {
  .contact-hero .contact-info ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    text-align: left;
    margin-top: 40px;
  }
}

.social-icons {
  display: flex;
  margin: 0;
  padding: 0;
}

.social-icons a {
  display: block;
  padding: 3px;
  fill: #666;
  width: 35px;
  height: 35px;
}

.social-icons a:hover {
  fill: #3fa208;
}

header .social-icons a {
  padding: 5px;
  width: 28px;
  height: 28px;
}

header .social-icons li {
  margin: 0;
  padding: 0;
}

header .social-icons svg {
  fill: #999;
  padding: 0;
}

header .social-icons svg:hover {
  fill: #3fa208;
}

@media (min-width: 980px) {
  .bg-open-ymca {
    background-image: url("/images/open-y-platform.jpg");
  }
  .bg-openy-intro {
    background-image: url("/images/open-y-platform-responsive.jpg");
  }
  .story-yoc {
    background-image: url("/images/open-y-platform-old-colony-ymca.jpg");
  }
  .story-openy-sfymca {
    background-image: url("/images/open-y-platform-sioux-falls-ymca.jpg");
  }
  .story-openy-wsymca {
    background-image: url("/images/open-y-platform-west-suburban-ymca.jpg");
  }
  .story-openy-sdymca {
    background-image: url("/images/open-y-platform-southern-district-ymca.jpg");
  }
  .story-openy-mwymca {
    background-image: url("/images/open-y-platform-metrowest-ymca.jpg");
  }
  .about-billy {
    background-image: url("/images/billy-background.jpg");
    background-color: #c1c1c1;
  }
  .about-chloe {
    background-image: url("/images/chloe-background.jpg");
    background-color: #f9fdff;
  }
  .about-love-dog {
    background-image: url("/images/love-dog-background.jpg");
    background-size: cover;
  }
  .about-greetings {
    background-image: url("/images/food-background.jpg");
    background-size: cover;
  }
  .home-whoweworkwith {
    background-image: url("/images/who-we-work-with.jpg");
  }
  .solutions-quote-chroma {
    background-image: url("/images/chroma-laptop.jpg");
  }
  .section-cms-systems {
    background-image: url("/images/CMSSystems.jpg");
  }
  .pavlov-dog {
    background-image: url("/images/pavlov-dog.jpg");
  }
  .pig-in-blanket {
    background-image: url("/images/pig-in-blanket.jpg");
  }
  .raven {
    background-image: url("/images/raven.jpg");
  }
}

.service-plugs h2, .service-plugs .h2 {
  margin-bottom: 40px;
}

.service-plug {
  margin-bottom: 30px;
}

/*
.service-plug:after
{
  content: "";
  display: block;
  margin-top:20px;
  width:20%;
  border-bottom:solid 1px #ccc;
}
*/
.service-plug h3, .service-plug .h3 {
  margin: 0;
}

.service-plug p {
  margin: 0 0 10px 0;
}

.service-plug h3, .service-plug .h3 {
  color: #fff;
}

.grid-3col {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 20px;
}

.grid-2col {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
}

.grid {
  text-align: left;
}

.grid-boxes > div {
  padding: 30px;
  text-align: left;
  background: #efefef;
  min-height: 100px;
  border-radius: 10px;
  border: solid 1px #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.knockout .grid-boxes > div {
  color: gray;
  background: #1a1a1a;
}

.section-quote {
  display: flex;
  text-align: left;
}

.section-quote > div {
  width: 60%;
}

.section-quote .quote p {
  margin: 0;
}

.section-quote .quote {
  display: flex;
  align-items: center;
  width: 40%;
  color: #3fa208;
  font-size: 175%;
  font-weight: 300;
  padding-right: 40px;
  text-align: right;
}

.section-quote .quote .source {
  font-size: 60%;
  color: #ccc;
  display: inline;
  margin-left: 10px;
}

.section-quote-background > div {
  padding-left: 60px;
}

.section-quote-background .quote {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
}

.section-quote-background .quote > div {
  background-color: rgba(255, 255, 255, 0.85);
  line-height: 1.25;
  padding: 20px 0;
}

.code, code {
  padding: 8px;
  border: solid 1px #f4e6bb;
  background: #fbf6e7;
  color: #586e7d;
}

.chatbot {
  position: fixed;
  bottom: 0;
  right: 50px;
  width: 360px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
  padding: 5px;
  border-top: solid 1px #ccc;
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  z-index: 99;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatbot-close {
  text-align: right;
  padding-right: 10px;
  text-transform: uppercase;
  color: #ccc;
  cursor: pointer;
}

.chatbot-content {
  padding: 10px;
  overflow-y: scroll;
  height: 100%;
  flex: 1 1 auto;
  height: 250px;
  width: 365px;
}

.chatbot-response {
  text-align: right;
}

.chatbot-response,
.chatbot-request {
  margin-bottom: 5px;
}

.chatbot-response div,
.chatbot-request div {
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
}

.chatbot-response div {
  background-color: blue;
  color: white;
}

.chatbot-request div {
  background-color: #efefef;
  border: solid 1px #ccc;
}

.chatbot-message {
  padding: 10px;
}

.chatbot-collapsed {
  min-height: auto;
}

.chatbot-collapsed .chatbot-message,
.chatbot-collapsed .chatbot-content {
  display: none;
}

.hero {
  background-color: #333;
  background-image: url("/images/home-hero-small.jpg");
  background-size: cover;
  color: #fff;
  overflow: hidden;
}

.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.7;
  background: #000;
}

.hero .wrap {
  min-height: 100vh;
  position: relative;
  z-index: 2;
}

.hero .section-rule {
  height: 20px;
  background-color: #679c42;
}

.hero .section-heading {
  font-size: 40px;
  line-height: 1;
  font-weight: 600;
  margin: 0;
}

.hero .section-subheading {
  font-size: 16px;
  margin: 10px 0 0 0;
}

.video-bg {
  display: none;
}

@media (min-width: 980px) {
  /*
  .hero
  {
    background-image:url('/images/home-hero-mock2.jpg');
  }
  */
  .hero .wrap {
    min-height: 80vh;
  }
  .hero .section-heading {
    font-size: 80px;
    font-weight: 600;
    margin: 0;
  }
  .hero .section-subheading {
    font-size: 24px;
    margin: 0;
  }
  .hero .overlay {
    background: linear-gradient(135deg, #333 45%, #000 55%);
  }
  .video-bg {
    display: inline;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .video-bg video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.home-intro .section-subheading {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  color: #3fa208;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.home-intro .section-subheading li {
  text-transform: uppercase;
  margin: 0;
}

.home-intro .section-subheading li:after {
  content: "•";
  padding: 0 10px;
}

.home-intro .section-subheading li:last-child:after {
  content: "";
  padding: 0;
}

@media (min-width: 980px) {
  .home-intro .section-subheading {
    font-size: 16px;
    justify-content: center;
  }
}

@media (min-width: 980px) {
  .home-crafting {
    background-image: url("/images/crafting.jpg");
  }
}

.integrated {
  background: #333;
  color: #999999;
}

.integrated li {
  background: #242424;
  color: #8c8c8c;
  text-align: left;
}

.integrated h3, .integrated .h3 {
  line-height: 1.25;
  color: #fff;
}

.integrated li {
  position: relative;
  z-index: 0;
}

.integrated li > div {
  margin-top: 100px;
}

.integrated .number {
  color: #999;
  border: 5px solid #999;
  border-radius: 75px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150px;
  font-weight: 300;
  overflow: hidden;
  position: absolute;
  opacity: 0.1;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (min-width: 980px) {
  .integrated p {
    line-height: 1.75;
    font-size: 15px;
  }
}

.services-list div.icon {
  padding: 5px 15px 0 0;
  fill: #3fa208;
  height: 40px;
}

.services-list div.icon svg {
  height: 100%;
  width: auto;
}

.services-list > div {
  margin-bottom: 80px;
}

.services-list h3, .services-list .h3 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}

.services-list p {
  margin-top: 0;
}

.services-list .link {
  font-weight: bold;
}

.services-list .link a {
  display: block;
}

@media (min-width: 980px) {
  /*
  .section-summary
  {
    display: flex;
    justify-content: center;
  }
  

  .section-summary > div
  {
    width:75%;
  }
  */
  .services-list {
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
  }
  .services-list > div {
    width: 30%;
    text-align: left;
    display: flex;
    align-items: flex-start;
  }
  .services-list div div {
    width: 85%;
  }
  .services-list div.icon {
    width: 15%;
  }
  .services-list h3, .services-list .h3 {
    font-size: 18px;
  }
  .services-list p {
    margin-top: 0;
  }
}

.story-content .statistics ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.story-content .statistics li span {
  text-transform: uppercase;
  font-weight: bold;
}

.story-content .statistics ul li {
  margin-bottom: 25px;
  white-space: nowrap;
}

.story-content .statistics ul li li {
  margin-bottom: 0;
}

.story-chroma .story-content .quote,
.story-chroma .story-content .statistics span {
  color: red;
}

.story-sfymca .story-content .quote,
.story-sfymca .story-content .statistics span {
  color: #ff6600;
}

@media (min-width: 980px) {
  .story-content {
    padding: 20px;
    background: white;
    display: flex;
    align-items: flex-start;
    border-top: 10px solid #999;
  }
  .story-content > div {
    padding: 20px;
  }
  .story-content .statistics ul {
    font-size: 70%;
    font-weight: bold;
  }
  .story-content .quote {
    margin: 0;
    line-height: 1.75;
  }
  .story-content .author {
    color: #000;
    margin: 10px 0 0 20px;
    line-height: 1.75;
  }
  .story-content .topic {
    text-transform: uppercase;
    font-weight: bold;
    color: #999;
  }
  .story-content .heading {
    font-size: 30px;
    font-weight: 300;
  }
  .story-content .statistics ul ul {
    font-size: 130%;
    font-weight: 300;
    text-transform: none;
    color: #666;
  }
  .story-stacked .story-content {
    flex-direction: column-reverse;
  }
  .story-stacked .story-content > div {
    width: 100%;
  }
  .story-stacked-left .wrap > div,
  .story-stacked-right .wrap > div {
    width: 60%;
  }
  .story-stacked .statistics > ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .story-stacked .story-content {
    border-top: none;
    background: none;
  }
  .story-chroma .story-content {
    border-top: 10px solid red;
  }
  .story-sfymca .story-content {
    border-top: 10px solid #ff6600;
  }
  .home-stories .section-summary {
    display: flex;
    justify-content: center;
  }
  .home-stories .section-summary > div {
    width: 50%;
  }
  .story-chroma {
    background-image: url("/images/chroma.jpg");
  }
  .story-sfymca {
    background-image: url("/images/souixfallsymca.jpg");
  }
  .story-care {
    background-image: url("/images/care.jpg");
  }
  .story-sfymca .wrap {
    min-height: 50vw;
  }
}

.service-stripe {
  display: none;
}

@media (min-width: 980px) {
  .service-stripe {
    border-top: 5px solid #3fa208;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    height: 125px;
    justify-content: center;
    align-items: stretch;
    z-index: 10;
  }
  .service-stripe > div {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 0 auto;
    flex: 1 1 100%;
    max-width: 1200px;
  }
  .service-stripe > div > div,
  .service-stripe > div > a {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
  }
  .service-stripe > div > a {
    cursor: pointer;
  }
  .service-stripe > div > a:hover {
    background: rgba(0, 0, 0, 0.3);
    color: #3fa208;
  }
  .service-stripe > div > div:last-child {
    border-right: none;
  }
}

@media (min-width: 980px) {
  .services-web {
    background-image: url("/images/web.jpg");
  }
  .services-marketing {
    background-image: url("/images/marketing.jpg");
  }
  .services-creative {
    background-image: url("/images/creative.jpg");
  }
  .section-growth-playbook {
    background-image: url("/images/growth-playbook.jpg");
  }
}

.blog-list {
  padding: 50px 0;
}

.blog-list > div {
  display: flex;
}

.blog-heading,
.blog-author {
  line-height: 1;
}

.blog-author {
  font-weight: bold;
  color: #999;
  font-size: 90%;
}

.blog-list article {
  flex: 1 1 50%;
  display: flex;
}

.blog-list article > div {
  padding: 20px;
}

.dogs-life {
  background-image: url("/images/blog/bg-living-a-dogs-life-is-hard-work.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.local-dns {
  background-image: url("/images/blog/how-to-use-local-dns-for-web-development.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-post {
  margin: 100px 0;
  font-size: 125%;
}

.blog-post .blog-lead {
  font-size: 150%;
  color: #3fa208;
}

.blog-post .container {
  max-width: 760px;
}

.blog-post h2, .blog-post .h2 {
  font-size: 125%;
  font-weight: bold;
  margin: 0;
}

.blog-post p {
  line-height: 2;
}

.section-bar {
  display: flex;
  justify-content: center;
}

.section-bar > div {
  padding: 10px;
  opacity: 0.5;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.section-bar > div:last-child {
  border: none;
}
