
.section-heading
{
  font-size:200%;
  font-weight:600;
  margin-bottom:$space-2;
  line-height:1.25;
}

.section-lead
{
  //font-size:90%;
  //line-height:2;
  margin:0;
}
.section-narrow
{
  display:flex;
  justify-content: center;
  > div
  { 
    width:65%;
  }
}

.section-spaced > div
{
  margin-bottom:20px;
}

.section-image
{
  margin-bottom:20px;
}

.section-topic
{
  text-transform: uppercase;
  font-weight:bold;
  font-size:90%;
  color:#999;
}

.section-title
{
  text-transform:uppercase;
  color:#666;
  font-size:14px;
  font-weight:bold;
}

main section .wrap
{
  margin: 0 auto;
  max-width: 1200px;
  width:90%;
  padding:50px 0;
  display: flex;
  align-items: center;
}

main section .wrap-center
{
  justify-content: center;
}

main section .wrap-center > div
{
  width:100%;
}

.section-divider
{
  display:flex;
  justify-content: center;
}

.section-divider > div
{
  background-color: $green;
  height:5px;
  width: 20%;
  margin:40px 0;
}
  
.section-background
{
  @extend .bg-light-gray;
}

.section-background-colored
{
  @extend .bg-green;
}

main section {
  border-bottom: 1px solid $medium-grey;
  position: relative;
}
  
section.no-border
{
  border-bottom: none;
}

.section-overlay
{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.7);
  z-index:0;
}

@include breakpoint($medium) { 

  main section,article {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right center;
  }

  .section-right
  {
    background-position: left center;
  }

  .section-image
  {
    display:none;
  }
  
  main section .wrap
  {
    min-height: 40vw;
  }

  main section .wrap-short
  {
    min-height: 20vw;
    padding: 0;
  }

  .section-heading
  {
    font-size:250%;
  }

  .section-lead
  {
    font-size:115%;
    line-height: 1.75;
  }

  main section .wrap-half > div
  {
    width:50%;
  }

  main section .wrap-full
  {
    max-width: initial;
  }

  .section-right .wrap
  {
    justify-content: flex-end;
  }

  main section .wrap-center-narrow > div
  {
    width:75%;
  }

  .section-right .wrap > div,
  .section-left .wrap > div,
  main section .wrap-right > div,
  main section .wrap-left > div
  {
    width:40%;
  }

  main section.wrap-left,
  article.wrap-left
  {
    background-position: right center;
  }


}
