.internal-notes
{
  position:absolute;
  font-size:11px;
  color:magenta;
  width:10%;
  left:10px;
  top:10px;
  z-index:30;

  .title
  {
    font-weight:bold;
    text-transform:uppercase;
  }
  .icon
  {
    width:20px;
    height:20px;
    background:magenta;
    color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:10px;
    font-weight:bold;
    cursor:pointer;
  }
  .contents
  {
    position:absolute;
    top:0;
    left:25px;
    display:none;
    background:white;
    padding:10px;
    border:solid 1px #ccc;
  }
  .contents-active
  {
    display:block;
  }
  ul
  {
    list-style:none;
    margin:0;
    padding:0;
  }

  li
  {
    margin-bottom:10px;
    padding:0 20px 0 0;
  }
 
}
