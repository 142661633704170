
.column-list div.icon
{
  padding: 5px 15px 0 0;
  fill: $green;
  height:40px;
}

.column-list div.icon svg
{
  height:100%;
  width:auto;
}

.column-list h3
{
  text-transform:uppercase;
  font-weight:600;
  font-size:16px;
  margin:0;
}

.column-list p
{
  margin-top:0;
  font-size:90%;
}

.column-list .link
{
  font-weight:bold;

  a {
    display:block;
  }
}


.technology-list
{
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 20px;
  color: #333;
}

.technology-list > ul
{
  padding:20px;
  margin:0;
  background-color: #efefef; //$soft-green;
  color: #666; //#fff;
  //font-size:115%;
  border-radius: 5px;
}

.technology-list ul
{
  list-style: none;
  text-align: left;
}

.technology-list ul ul
{
  padding: 10px 20px 0 0;
}

.technology-list ul ul ul
{
  padding: 0 20px;
}

.technology-list .heading
{
  font-weight:bold;
  text-transform:uppercase;
}

.technology-list li
{
  margin-bottom: 10px;
  font-size:95%;
}

.technology-list li li li
{
  margin-bottom: 0;
  font-weight: 300;
}

@include breakpoint($medium) { 

  .column-list
  {
    align-items: flex-start;
    justify-content: space-between;
    display:flex;
  }
  
  .column-list > div
  {
    flex:1 1 100%;
  }

  .column-list-3col {
    flex-wrap: wrap;
    > div {
      width: 30%;
    }
  }

  .column-list > div
  {
    text-align: left;
    display:flex;
    padding:0 20px;
    margin-bottom: 60px;
    align-items: flex-start;
  }

  .column-list div div
  {
    width:85%;
  }

  .column-list div.icon
  {
    width:15%;
  }

}
