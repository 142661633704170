.contact-page-form > div
{
  display:flex;
}

.contact-page-form > div div
{
  padding:10px;
  text-align:left !important;
}

.contact-page-form span
{
  font-size:12px;
  opacity:0.4;
  text-transform: uppercase;
}

.contact-hero .contact-info h3
{
  text-transform: uppercase;
  font-size: 90%;
  font-weight: bold;
  color:#666;
  border-bottom: 1px solid #fff;
}
@include breakpoint($medium) 
{ 

  .contact-hero .contact-info ul
  {
    display:flex;
    justify-content: space-around;
    list-style: none;
    text-align: left;
    margin-top:$space-3;
  }

}
