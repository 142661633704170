.services-list div.icon
{
  padding: 5px 15px 0 0;
  fill: $green;
  height:40px;
}

.services-list div.icon svg
{
  height:100%;
  width:auto;
}

.services-list > div
{
  margin-bottom: $space-4;
}

.services-list h3
{
  text-transform:uppercase;
  font-weight:600;
  font-size:22px;
  margin:0;
}

.services-list p
{
  margin-top:0;
  //font-size:90%;
}


.services-list .link
{
  font-weight:bold;

  a {
    display:block;
  }
}

@include breakpoint($medium) { 

  /*
  .section-summary
  {
    display: flex;
    justify-content: center;
  }
  

  .section-summary > div
  {
    width:75%;
  }
  */
  .services-list
  {
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    display:flex;
  }

  .services-list > div
  {
    width: 30%;
    text-align: left;
    display:flex;
    //margin-bottom: 0;
    align-items: flex-start;
  }

  .services-list div div
  {
    width:85%;
  }

  .services-list div.icon
  {
    width:15%;
  }

  .services-list h3
  {
    font-size:18px;
  }

  .services-list p
  {
    margin-top: 0;
  }



}
