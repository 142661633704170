


.p0 { padding: $space-0; }
.p1 { padding: $space-1; }
.p2 { padding: $space-2; }
.p3 { padding: $space-3; }

.px0 { padding-left: $space-0; padding-right: $space-0; }
.px1 { padding-left: $space-1; padding-right: $space-1; }
.px2 { padding-left: $space-2; padding-right: $space-2; }
.px3 { padding-left: $space-3; padding-right: $space-3; }

.py0 { padding-top: $space-0; padding-bottom: $space-0; }
.py1 { padding-top: $space-1; padding-bottom: $space-1; }
.py2 { padding-top: $space-2; padding-bottom: $space-2; }
.py3 { padding-top: $space-3; padding-bottom: $space-3; }

.pl0 { padding-left: $space-0; }
.pl1 { padding-left: $space-1; }
.pl2 { padding-left: $space-2; }
.pl3 { padding-left: $space-3; }

.pr0 { padding-right: $space-0; }
.pr1 { padding-right: $space-1; }
.pr2 { padding-right: $space-2; }
.pr3 { padding-right: $space-3; }

.pt0 { padding-top: $space-0; }
.pt1 { padding-top: $space-1; }
.pt2 { padding-top: $space-2; }
.pt3 { padding-top: $space-3; }

.pb0 { padding-bottom: $space-0; }
.pb1 { padding-bottom: $space-1; }
.pb2 { padding-bottom: $space-2; }
.pb3 { padding-bottom: $space-3; }

.m0 { margin: $space-0; }
.m1 { margin: $space-1; }
.m2 { margin: $space-2; }
.m3 { margin: $space-3; }

.mx0 { margin-left: $space-0; margin-right: $space-0; }
.mx1 { margin-left: $space-1; margin-right: $space-1; }
.mx2 { margin-left: $space-2; margin-right: $space-2; }
.mx3 { margin-left: $space-3; margin-right: $space-3; }

.my0 { margin-top: $space-0; margin-bottom: $space-0; }
.my1 { margin-top: $space-1; margin-bottom: $space-1; }
.my2 { margin-top: $space-2; margin-bottom: $space-2; }
.my3 { margin-top: $space-3; margin-bottom: $space-3; }

.ml0 { margin-left: $space-0; }
.ml1 { margin-left: $space-1; }
.ml2 { margin-left: $space-2; }
.ml3 { margin-left: $space-3; }

.mr0 { margin-right: $space-0; }
.mr1 { margin-right: $space-1; }
.mr2 { margin-right: $space-2; }
.mr3 { margin-right: $space-3; }

.mt0 { margin-top: $space-0; }
.mt1 { margin-top: $space-1; }
.mt2 { margin-top: $space-2; }
.mt3 { margin-top: $space-3; }

.mb0 { margin-bottom: $space-0 !important; }
.mb1 { margin-bottom: $space-1 !important; }
.mb2 { margin-bottom: $space-2 !important; }
.mb3 { margin-bottom: $space-3 !important; }

@include breakpoint($medium) 
{ 

  .offset0 { margin-left: -$space-0; margin-right: -$space-0; }
  .offset1 { margin-left: -$space-1; margin-right: -$space-1; }
  .offset2 { margin-left: -$space-2; margin-right: -$space-2; }
  .offset3 { margin-left: -$space-3; margin-right: -$space-3; }

}


