.show-subnav
{
  position:relative;
}

.subnav-panel
{
  //opacity: 0; 
  //visibility: hidden;
  display:none;
  position:absolute;
  top: 100%;
  left: 0;
  background:$green;
  //transition: opacity 200ms, visibility 200ms;
}

.subnav-drop-down-item ul
{
  background:$green;
}

.subnav-panel li
{
  position: relative;
}

.subnav-panel li ul
{
  position: absolute;
  left:100%;
  top:0;
  }

.show-subnav-active
{
  background-color: $green;
  color:#fff !important;
}

nav .show-subnav-active a
{
  color:#fff;
}

.show-subnav-active svg
{
  fill:#fff;
}

.subnav-panel ul
{
  display:block;
  margin:20px;
  padding: 0;
  list-style: none;
  justify-content: space-around;
  min-width: 230px;
}

.subnav-panel li
{
  color:#fff;
  font-size: 14px;
}

.subnav-panel li a,
.subnav-panel li span
{
  display:block;
  padding:10px 20px;
}
.subnav-drop-down-item
{
  position:relative;
  width:100%;
}

.subnav-drop-down-item:after
{
  content: '';
  position: absolute;
  right: 10px;
  top: 40%;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #fff;
}

.subnav-drop-down-item:hover,
.subnav-panel a:hover
{
  background-color: darken($green,5%);
  cursor:pointer;
}

.subnav-panel ul,
.subnav-panel li
{
  margin:0;
  padding: 0;
}

.subnav-panel ul ul
{
  display:none;
  height: initial;
}


/*
.subnav-panel > div
{
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}



.subnav-panel ul ul
{
  display:block;
}
*/

.subnav-panel a {
    display: flex;
    align-items: flex-center;
    margin: 0;
    padding: 5px 0;
    text-transform: none;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: initial;
    width:100%;
}


@include breakpoint($medium) 
{ 
  .subnav-panel-active
  {
     opacity: 1; 
     visibility: visible;
  }
}
