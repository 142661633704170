 .flex-blocks
{
  margin:0;
  padding:0;
  list-style:none;
}

.flex-blocks > li
{
  background-color: #fff;
  border:solid 1px #ccc;
  border-radius:10px;
  min-height:200px;
  display:block;
  margin:20px 0;
  padding:20px;
  box-shadow:2px 2px 5px rgba(0,0,0,0.1);
}

.flex-blocks ul
{
  list-style:none;
  margin:0;
  padding:0;
  text-align:left;
}

 .flex-blocks > li
{
  display:flex;
  justify-content:center;
  align-items: flex-start;
}  

.flex-blocks-noborder > li
{
  border:none;
  box-shadow:none;
}

.flex-blocks-center > li
{
  align-items: center;
  text-align: center;
}


@include breakpoint($medium) { 

  .flex-blocks
  {
    display:flex;
    flex-wrap:wrap;
  }

  .flex-blocks > li
  {
    flex:1 1 20%;
    margin:5px;
  }  

  .flex-blocks-nowrap
  {
    flex-wrap:nowrap;
  }

}
