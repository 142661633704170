
.smaller
{
  font-size:85% !important;
}

.tiny
{
  font-size:60% !important;
}

.larger
{
  font-size:150% !important;
}

.thin
{
  font-weight:300 !important;
}

.muted{
  opacity:.65;
}
.bold {
  font-weight:600 !important;
}
.italic{
  font-style:italic;
}
.uppercase { text-transform:uppercase; } 

.line-height-1{ line-height:1; }
.line-height-2{ line-height:1.5; }
.line-height-3{ line-height:2; }
.line-height-4{ line-height:4; }

.align-center { text-align:center; }
.align-left  { text-align:left; }
.align-right  { text-align:right; }

.h1{ @extend h1;  }
.h2{ @extend h2; }
.h3{ @extend h3; }
.h4{ @extend h4; }
.h5{ @extend h5; }
.h6{ @extend h6; }

