.social-icons
{
  display:flex;
  margin:0;
  padding:0;
}

.social-icons a
{
  display:block;
  padding:3px;
  fill:#666;
  width: 35px;
  height: 35px;
}

.social-icons a:hover
{
  fill:$green;
}

header .social-icons a
{
  padding:5px;
  width: 28px;
  height: 28px;
}

header .social-icons li
{
  margin:0;
  padding:0;
}

header .social-icons svg
{
  fill:#999;
  padding:0;
}

header .social-icons svg:hover
{
  fill:$green;
}
